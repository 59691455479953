// Generated by Framer (cbb0119)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-dGRa5"

const variantClassNames = {j54iM76GD: "framer-v-1xqphn9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "j54iM76GD", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1xqphn9", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"j54iM76GD"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-1jxmn57"} data-framer-name={"ic-github-24"} layout={"position"} layoutDependency={layoutDependency} layoutId={"hZspG9_aI"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 24 24\"><path d=\"M 12 1.5 C 6.199 1.5 1.5 6.199 1.5 12 C 1.5 16.646 4.506 20.57 8.68 21.962 C 9.204 22.054 9.401 21.739 9.401 21.463 C 9.401 21.213 9.388 20.387 9.388 19.507 C 6.75 19.993 6.068 18.864 5.858 18.274 C 5.739 17.972 5.228 17.04 4.781 16.791 C 4.414 16.594 3.889 16.108 4.768 16.095 C 5.595 16.082 6.186 16.856 6.383 17.171 C 7.327 18.759 8.837 18.313 9.441 18.038 C 9.532 17.355 9.808 16.896 10.11 16.633 C 7.774 16.371 5.333 15.465 5.333 11.449 C 5.333 10.307 5.739 9.362 6.409 8.627 C 6.304 8.364 5.936 7.288 6.514 5.844 C 6.514 5.844 7.394 5.569 9.401 6.921 C 10.256 6.684 11.139 6.564 12.026 6.566 C 12.919 6.566 13.811 6.684 14.651 6.921 C 16.659 5.556 17.539 5.844 17.539 5.844 C 18.116 7.288 17.749 8.364 17.644 8.627 C 18.314 9.362 18.72 10.294 18.72 11.449 C 18.72 15.479 16.266 16.371 13.93 16.633 C 14.31 16.961 14.638 17.591 14.638 18.576 C 14.638 19.98 14.625 21.109 14.625 21.463 C 14.625 21.739 14.822 22.067 15.347 21.962 C 19.621 20.519 22.499 16.511 22.5 12 C 22.5 6.199 17.801 1.5 12 1.5 Z\" fill=\"var(--token-1b444440-6e98-401c-93cc-651f6c08f890, rgb(20, 20, 31)) /* {&quot;name&quot;:&quot;primary-black&quot;} */\"></path></svg>"} svgContentId={11076189416} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-dGRa5.framer-1316u4, .framer-dGRa5 .framer-1316u4 { display: block; }", ".framer-dGRa5.framer-1xqphn9 { height: 24px; overflow: visible; position: relative; width: 24px; }", ".framer-dGRa5 .framer-1jxmn57 { flex: none; height: 24px; left: calc(50.00000000000002% - 24px / 2); position: absolute; top: calc(50.00000000000002% - 24px / 2); width: 24px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerFBdbZXLzm: React.ComponentType<Props> = withCSS(Component, css, "framer-dGRa5") as typeof Component;
export default FramerFBdbZXLzm;

FramerFBdbZXLzm.displayName = "ic";

FramerFBdbZXLzm.defaultProps = {height: 24, width: 24};

addFonts(FramerFBdbZXLzm, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})